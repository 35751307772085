"use client"

import { SxProps, Theme } from "@mui/material"
import Box from "@mui/material/Box"
import { ReactNode, useEffect, useRef, useState } from "react"

interface Props {
    children: ReactNode
    duration?: string
    delay?: string
    visible?: boolean
    sx?: SxProps<Theme>
}

const SlideUpAnimation = ({
    children,
    duration = "0.6s",
    delay = "0.1s",
    visible = false,
    sx = {},
}: Props) => {
    const [isVisible, setIsVisible] = useState(false)
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (visible) {
            setIsVisible(true)
        }
    }, [visible])

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true)
                    observer.disconnect()
                }
            },
            {
                threshold: 0.1,
            }
        )

        if (ref.current) {
            observer.observe(ref.current)
        }

        return () => {
            observer.disconnect()
        }
    }, [ref])

    return (
        <Box
            ref={ref}
            sx={{
                width: "100%",
                opacity: 0,
                animation: isVisible ? `${duration} ${delay} slide-up ease forwards` : "none",
                "@keyframes slide-up": {
                    "0%": {
                        opacity: 0,
                        transform: "translateY(30px)",
                    },
                    "100%": {
                        opacity: 1,
                        transform: "translateY(0)",
                    },
                },
                ...sx,
            }}
        >
            {children}
        </Box>
    )
}

export default SlideUpAnimation
