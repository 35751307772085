"use client"
import { getTheme } from "@/theme"
import { alpha, SxProps, Theme, Typography, TypographyProps } from "@mui/material"
import { PropsWithChildren } from "react"

type TitleProps = TypographyProps & {
    hasBlueGradient?: boolean
}

export const Title = ({
    children,
    sx = {},
    hasBlueGradient,
    ...props
}: PropsWithChildren<TitleProps>) => {
    const style = {
        ...styles.title,
        ...sx,
        ...(hasBlueGradient ? styles.blueGradient : {})
    }

    return (
        <Typography component="h1" variant="h2" sx={style} {...props}>
            {children}
        </Typography>
    )
}

export const TitleSecondary = ({
    children,
    sx = {},
    ...props
}: PropsWithChildren<TypographyProps>) => {
    const style = { ...styles.titleSecondary, ...sx }
    return (
        <Typography component="h2" sx={style} {...props}>
            {children}
        </Typography>
    )
}

export const TitleTertiary = ({
    children,
    sx = {},
    ...props
}: PropsWithChildren<TypographyProps>) => {
    const style = { ...styles.titleTertiary, ...sx }
    return (
        <Typography component="h3" sx={style} {...props}>
            {children}
        </Typography>
    )
}

export const SubTitle = ({ sx = {}, ...props }: PropsWithChildren<TypographyProps>) => {
    const style = { ...styles.subtitle, ...sx }
    return <Title variant="h4" component="h2" {...props} sx={style} />
}

export const SubSectionTitle = ({ sx = {}, ...props }: PropsWithChildren<TypographyProps>) => {
    const style = { ...styles.subSectionTitle, ...sx }
    return <Title variant="h6" component="h3" {...props} sx={style} />
}

const theme = getTheme()

const styles: Record<string, SxProps<Theme>> = {
    title: {
        textAlign: "center",
        pt: 4,
        pb: 3,
        lineHeight: 1.3,
        fontWeight: 600,
        fontSize: "55px",
        background:
            "radial-gradient(70.71% 70.71% at 50% 50%,  #FFF 0%, rgba(255, 255, 255, 0.60)  100%)",
        backgroundClip: "text",
        WebkitBackgroundClip: "text",
        color: "transparent",
        [theme.breakpoints.down("md")]: {
            fontSize: theme.typography.h4.fontSize,
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "24px",
        },
    },
    blueGradient: {
        background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.common.white})`,
    },
    titleSecondary: {
        color: "text.primary",
        fontWeight: 500,
        fontSize: "20px",
        [theme.breakpoints.down("md")]: {
            fontSize: "18px",
            fontWeight: 500,
            lineHeight: "24px",
        },

        [theme.breakpoints.down("sm")]: {
            fontSize: "16px",
        },
    },
    titleTertiary: {
        fontSize: "15px",
        color: "text.secondary",
        [theme.breakpoints.down("md")]: {
            fontSize: "14px",
            lineHeight: "22px",
        },

        [theme.breakpoints.down("sm")]: {
            fontSize: "14px",
        },
    },
    subtitle: {
        color: theme.palette.text.primary,
        fontWeight: 600,
        textAlign: "left",
        pt: 2,
        pb: 1,

        fontSize: theme.typography.h4.fontSize,

        [theme.breakpoints.down("md")]: {
            fontSize: theme.typography.h5.fontSize,
        },

        [theme.breakpoints.down("sm")]: {
            fontSize: theme.typography.h6.fontSize,
        },
    },
    subSectionTitle: {
        color: theme.palette.text.primary,
        fontWeight: 500,
        textAlign: "left",
        py: 1,
        fontSize: theme.typography.h5.fontSize,

        [theme.breakpoints.down("md")]: {
            fontSize: theme.typography.h6.fontSize,
        },

        [theme.breakpoints.down("sm")]: {
            fontSize: theme.typography.h6.fontSize,
        },
    },
}
